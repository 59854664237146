var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
class WindowNetworkListener {
  static getIsOnline() {
    return __awaiter(this, void 0, void 0, function* () {
      return navigator.onLine;
    });
  }
  static listen(f) {
    const onOnline = () => {
      f(true);
    };
    const onOffline = () => {
      f(false);
    };
    addEventListener("online", onOnline);
    addEventListener("offline", onOffline);
    return () => {
      removeEventListener("online", onOnline);
      removeEventListener("offline", onOffline);
    };
  }
}

export { WindowNetworkListener as default };