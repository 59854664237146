function transactionChunk(etype, id, prevOps) {
  return new Proxy({}, {
    get: (_target, cmd) => {
      if (cmd === "__ops") return prevOps;
      return args => {
        return transactionChunk(etype, id, [...prevOps, [cmd, etype, id, args]]);
      };
    }
  });
}
/**
 * Creates a lookup to use in place of an id in a transaction
 *
 * @example
 * tx.users[lookup('email', 'lyndon@example.com')].update({name: 'Lyndon'})
 */
export function lookup(attribute, value) {
  return `lookup__${attribute}__${JSON.stringify(value)}`;
}
export function isLookup(k) {
  return k.startsWith("lookup__");
}
export function parseLookup(k) {
  const [_, attribute, ...vJSON] = k.split("__");
  return [attribute, JSON.parse(vJSON.join("__"))];
}
function etypeChunk(etype) {
  return new Proxy({}, {
    get(_target, id) {
      if (isLookup(id)) {
        return transactionChunk(etype, parseLookup(id), []);
      }
      return transactionChunk(etype, id, []);
    }
  });
}
export function txInit() {
  return new Proxy({}, {
    get(_target, ns) {
      return etypeChunk(ns);
    }
  });
}
/**
 * A handy builder for changes.
 *
 * You must start with the `namespace` you want to change:
 *
 * @example
 *   tx.goals[goalId].update({title: "Get fit"})
 *   // Note: you don't need to create `goals` ahead of time.
 */
export const tx = txInit();
export function getOps(x) {
  return x.__ops;
}
