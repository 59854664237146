var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
import { jsonFetch } from "./utils/fetch";
export function getSignedUploadUrl(_a) {
  return __awaiter(this, arguments, void 0, function* ({
    apiURI,
    appId,
    fileName,
    refreshToken,
    metadata = {}
  }) {
    const {
      data
    } = yield jsonFetch(`${apiURI}/storage/signed-upload-url`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        authorization: `Bearer ${refreshToken}`
      },
      body: JSON.stringify({
        app_id: appId,
        filename: fileName
      })
    });
    return data;
  });
}
export function upload(presignedUrl, file) {
  return __awaiter(this, void 0, void 0, function* () {
    const response = yield fetch(presignedUrl, {
      method: "PUT",
      body: file,
      headers: {
        "Content-Type": file.type
      }
    });
    return response.ok;
  });
}
export function getDownloadUrl(_a) {
  return __awaiter(this, arguments, void 0, function* ({
    apiURI,
    appId,
    path,
    refreshToken
  }) {
    const {
      data
    } = yield jsonFetch(`${apiURI}/storage/signed-download-url?app_id=${appId}&filename=${encodeURIComponent(path)}`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        authorization: `Bearer ${refreshToken}`
      }
    });
    return data;
  });
}
export function deleteFile(_a) {
  return __awaiter(this, arguments, void 0, function* ({
    apiURI,
    appId,
    path,
    refreshToken
  }) {
    const {
      data
    } = yield jsonFetch(`${apiURI}/storage/files?app_id=${appId}&filename=${encodeURIComponent(path)}`, {
      method: "DELETE",
      headers: {
        "content-type": "application/json",
        authorization: `Bearer ${refreshToken}`
      }
    });
    return data;
  });
}
