export function createLinkIndex(schema) {
  return Object.values(schema.links).reduce((linkIndex, link) => {
    var _a, _b;
    var _c, _d;
    (_a = linkIndex[_c = link.forward.on]) !== null && _a !== void 0 ? _a : linkIndex[_c] = {};
    linkIndex[link.forward.on][link.forward.label] = {
      isForward: true,
      isSingular: link.forward.has === "one",
      link
    };
    (_b = linkIndex[_d = link.reverse.on]) !== null && _b !== void 0 ? _b : linkIndex[_d] = {};
    linkIndex[link.reverse.on][link.reverse.label] = {
      isForward: false,
      isSingular: link.reverse.has === "one",
      link
    };
    return linkIndex;
  }, {});
}
