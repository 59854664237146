var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
import { jsonFetch } from "./utils/fetch";
export function sendMagicCode({
  apiURI,
  appId,
  email
}) {
  return jsonFetch(`${apiURI}/runtime/auth/send_magic_code`, {
    method: "POST",
    headers: {
      "content-type": "application/json"
    },
    body: JSON.stringify({
      "app-id": appId,
      email
    })
  });
}
export function verifyMagicCode(_a) {
  return __awaiter(this, arguments, void 0, function* ({
    apiURI,
    appId,
    email,
    code
  }) {
    const res = yield jsonFetch(`${apiURI}/runtime/auth/verify_magic_code`, {
      method: "POST",
      headers: {
        "content-type": "application/json"
      },
      body: JSON.stringify({
        "app-id": appId,
        email,
        code
      })
    });
    return res;
  });
}
export function verifyRefreshToken(_a) {
  return __awaiter(this, arguments, void 0, function* ({
    apiURI,
    appId,
    refreshToken
  }) {
    const res = yield jsonFetch(`${apiURI}/runtime/auth/verify_refresh_token`, {
      method: "POST",
      headers: {
        "content-type": "application/json"
      },
      body: JSON.stringify({
        "app-id": appId,
        "refresh-token": refreshToken
      })
    });
    return res;
  });
}
/**
 * @param {Object} params
 * @param {string} params.apiURI
 * @param {string} params.appId
 * @param {string} params.code
 * @param {string | null | undefined} [params.codeVerifier]
 */
export function exchangeCodeForToken(_a) {
  return __awaiter(this, arguments, void 0, function* ({
    apiURI,
    appId,
    code,
    codeVerifier
  }) {
    const res = yield jsonFetch(`${apiURI}/runtime/oauth/token`, {
      method: "POST",
      headers: {
        "content-type": "application/json"
      },
      body: JSON.stringify({
        app_id: appId,
        code: code,
        code_verifier: codeVerifier
      })
    });
    return res;
  });
}
/**
 * @param {Object} params
 * @param {string} params.apiURI
 * @param {string} params.appId
 * @param {string} params.clientName
 * @param {string} params.idToken
 * @param {string | null | undefined} [params.refreshToken]
 * @param {string | null | undefined} [params.nonce]
 */
export function signInWithIdToken(_a) {
  return __awaiter(this, arguments, void 0, function* ({
    apiURI,
    appId,
    nonce,
    idToken,
    clientName,
    refreshToken
  }) {
    const res = yield jsonFetch(`${apiURI}/runtime/oauth/id_token`, {
      method: "POST",
      headers: {
        "content-type": "application/json"
      },
      body: JSON.stringify({
        app_id: appId,
        nonce,
        id_token: idToken,
        client_name: clientName,
        refresh_token: refreshToken
      })
    });
    return res;
  });
}
/**
 * @param {Object} params
 * @param {string} params.apiURI
 * @param {string} params.appId
 * @param {string} params.refreshToken
 */
export function signOut(_a) {
  return __awaiter(this, arguments, void 0, function* ({
    apiURI,
    appId,
    refreshToken
  }) {
    const res = yield jsonFetch(`${apiURI}/runtime/signout`, {
      method: "POST",
      headers: {
        "content-type": "application/json"
      },
      body: JSON.stringify({
        app_id: appId,
        refresh_token: refreshToken
      })
    });
    return res;
  });
}
