import { pick } from "./utils/pick";
import { areObjectsShallowEqual, areObjectKeysEqual } from "./utils/object";
export function buildPresenceSlice(data, opts, userPeerId) {
  var _a, _b;
  const slice = {
    peers: {}
  };
  const includeUser = opts && "user" in opts ? opts.user : true;
  if (includeUser) {
    const user = pick((_a = data.user) !== null && _a !== void 0 ? _a : {}, opts === null || opts === void 0 ? void 0 : opts.keys);
    slice.user = Object.assign(Object.assign({}, user), {
      peerId: userPeerId
    });
  }
  for (const id of Object.keys((_b = data.peers) !== null && _b !== void 0 ? _b : {})) {
    const shouldIncludeAllPeers = (opts === null || opts === void 0 ? void 0 : opts.peers) === undefined;
    const isPeerIncluded = Array.isArray(opts === null || opts === void 0 ? void 0 : opts.peers) && (opts === null || opts === void 0 ? void 0 : opts.peers.includes(id));
    if (shouldIncludeAllPeers || isPeerIncluded) {
      const peer = pick(data.peers[id], opts === null || opts === void 0 ? void 0 : opts.keys);
      slice.peers[id] = Object.assign(Object.assign({}, peer), {
        peerId: id
      });
    }
  }
  return slice;
}
/**
 * Compare two presence slices
 * 0. compare isLoading and error
 * 1. shallow compare user
 * 2. compare peers keys
 * 3. shallow compare each peer
 */
export function hasPresenceResponseChanged(a, b) {
  if (a.isLoading !== b.isLoading) return true;
  if (a.error !== b.error) return true;
  if (a.user || b.user) {
    if (!a.user || !b.user) return true;
    const same = areObjectsShallowEqual(a.user, b.user);
    if (!same) return true;
  }
  const sameKeys = areObjectKeysEqual(a.peers, b.peers);
  if (!sameKeys) return true;
  for (const id of Object.keys(a.peers)) {
    const same = areObjectsShallowEqual(a.peers[id], b.peers[id]);
    if (!same) return true;
  }
  return false;
}
