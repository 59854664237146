var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
class IndexedDBStorage {
  constructor(dbName) {
    this.dbName = dbName;
    this._storeName = "kv";
    this._dbPromise = this._init();
  }
  _init() {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(this.dbName, 1);
      request.onerror = event => {
        reject(event);
      };
      request.onsuccess = event => {
        resolve(event.target.result);
      };
      request.onupgradeneeded = event => {
        const db = event.target.result;
        db.createObjectStore(this._storeName);
      };
    });
  }
  getItem(k) {
    return __awaiter(this, void 0, void 0, function* () {
      const db = yield this._dbPromise;
      return new Promise((resolve, reject) => {
        const transaction = db.transaction([this._storeName], "readonly");
        const objectStore = transaction.objectStore(this._storeName);
        const request = objectStore.get(k);
        request.onerror = event => {
          reject(event);
        };
        request.onsuccess = _event => {
          if (request.result) {
            resolve(request.result);
          } else {
            resolve(null);
          }
        };
      });
    });
  }
  setItem(k, v) {
    return __awaiter(this, void 0, void 0, function* () {
      const db = yield this._dbPromise;
      return new Promise((resolve, reject) => {
        const transaction = db.transaction([this._storeName], "readwrite");
        const objectStore = transaction.objectStore(this._storeName);
        const request = objectStore.put(v, k);
        request.onerror = event => {
          reject(event);
        };
        request.onsuccess = _event => {
          resolve();
        };
      });
    });
  }
}

export { IndexedDBStorage as default };