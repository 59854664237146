import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { init, User } from "@instantdb/core";
import { OidcSecurityService } from "angular-auth-oidc-client";
import { firstValueFrom } from "rxjs";

@Injectable({ providedIn: "root" })
export class DBService {
  private APP_ID = "0f194479-483f-4ba6-a794-1d622c04510f";
  // public todos: Todo[] = [];
  public db = init<Schema>({ appId: this.APP_ID });
  public user: User | null = null;
  public isAuthenticated = true;
  constructor(
    public oidcSecurityService: OidcSecurityService,
    private _client: HttpClient
  ) {}

  public logout() {
    this.db.auth.signOut();
  }

  public isDbAuth() {
    const toReturn = new Promise<boolean>((resolve, reject) => {
      this.db.subscribeAuth((data) => {
        if (data.user) {
          this.user = data.user!;
          resolve(true);
        } else {
          resolve(false);
        }
      });
    });
    return toReturn;
  }

  public async authUser(authToken: string) {
    const dbAuthToken = await firstValueFrom(
      this._client.get("https://note-api.miroslavpetro.com/auth", {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        responseType: "text",
      })
    );

    if (dbAuthToken) {
      await this.db.auth.signInWithToken(dbAuthToken);
      return true;
    }
    return false;
  }
}

// export interface Todo {
//   id: string;
//   text: string;
//   done: boolean;
//   createdAt: number;
//   createdBy: string;
// }

export interface Tag {
  id: string;
  name: string;
  createdAt: number;
  createdBy: string;
  color: string;
  note: Note[];
}

export interface Note {
  id: string;
  text: string;
  createdAt: number;
  createdBy: string;
  tag: Tag[];
}

export type Schema = {
  // todo: Todo;
  tag: Tag;
  note: Note;
};
